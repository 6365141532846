import React, { useEffect, useState } from 'react';
import './MainScreen.css';
import logo from '../img/logoComeal.png'; // Importer l'image
import { auth, db } from '../firebase'; // Importer Firestore et Firebase Storage
import { collection, getDocs } from 'firebase/firestore'; // Importer les fonctions Firestore
import { onAuthStateChanged } from 'firebase/auth'; // Pour récupérer l'utilisateur connecté
import PhotoGrid from './PhotoGrid'; // Adjust the path based on where you store PhotoGrid.js
import Chat from './ChatComponent'; // Importer le composant Chat

const MainScreen = () => {
  const [clients, setClients] = useState([]); // Liste des clients
  const [selectedClient, setSelectedClient] = useState(null); // Client sélectionné
  const [currentUser, setCurrentUser] = useState(null); // Utilisateur actuellement connecté
  const [searchTerm, setSearchTerm] = useState(''); // Terme de recherche pour filtrer les clients
  const [view, setView] = useState('calendrier'); // Vue actuellement affichée: 'calendrier' ou 'chat'

  // Fetch des clients depuis Firestore
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsCollection = collection(db, 'Client');
        const clientsSnapshot = await getDocs(clientsCollection);
        const clientsList = clientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setClients(clientsList);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients :", error);
      }
    };

    fetchClients();
  }, []);

  // Récupération de l'utilisateur actuellement connecté via Firebase Authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user); // Utilisateur connecté
      } else {
        setCurrentUser(null); // Pas d'utilisateur connecté
      }
    });
    return () => unsubscribe(); // Nettoyer l'écouteur à la déconnexion
  }, []);

  // Fonction pour sélectionner un client
  const handleClientClick = (clientId) => {
    setSelectedClient(clientId);
  };

  return (
    <div className="main-screen">
      {/* Sidebar gauche */}
      <div className="sidebar">
        <img src={logo} alt="Logo" className="logo" />
        <h2>Mes Clients</h2>
        <input
          type="text"
          placeholder="Rechercher un client"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <ul>
          {clients
            .filter(client => client.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map(client => (
              <li 
                key={client.id} 
                onClick={() => handleClientClick(client.id)} 
                className={selectedClient === client.id ? 'selected' : ''}
              >
                {client.name}
              </li>
            ))}
        </ul>
      </div>

      {/* Contenu central, on affiche soit le Calendrier soit le Chat */}
      <div className="content">
        {view === 'calendrier' ? (
          <>
            <h1>Calendrier de Publications</h1>
            <h2>Octobre 2024</h2>
            {/* Insérer le composant PhotoGrid ici */}
            <div className="photo-grid-section">
              <PhotoGrid />
            </div>
          </>
        ) : view === 'chat' && selectedClient && currentUser ? (
          <Chat selectedClientId={selectedClient} currentUserUid={currentUser.uid} />
        ) : (
          <p>Veuillez sélectionner un client pour démarrer le chat.</p>
        )}
      </div>

      {/* Sidebar droite */}
      <div className="sidebar">
        <img src={logo} alt="Logo" className="logo" />
        <h2>Actions</h2>
        <div className="button-group">
          {/* Utiliser onClick pour changer la vue */}
          <button className="action-button" onClick={() => setView('calendrier')}>
            Calendrier de Publications
          </button>
          <button className="action-button" onClick={() => setView('chat')}>
            Chat en direct
          </button>
          <button className="action-button">Envoi de Notifications</button>
        </div>
      </div>
    </div>
  );
};

export default MainScreen;
