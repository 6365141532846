import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Importer Firebase Storage
import { getDatabase } from 'firebase/database'; // Importer Firebase Realtime Database

// Votre configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDLhA60-IXpg16j6ox3nIDKNyV23w2vfyk",
  authDomain: "comealapplication.firebaseapp.com",
  databaseURL: "https://comealapplication-default-rtdb.firebaseio.com", // Important pour la Realtime Database
  projectId: "comealapplication",
  storageBucket: "comealapplication.appspot.com",
  messagingSenderId: "352962419492",
  appId: "1:352962419492:web:07b60ccecb59789fabb1b1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialiser Firebase Storage
const database = getDatabase(app); // Initialiser Firebase Realtime Database

export { auth, db, storage, database };
