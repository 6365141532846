import React, { useEffect, useState, useRef } from 'react';
import { ref as dbRef, onValue, push } from 'firebase/database';
import { database, auth } from '../firebase';

const Chat = ({ selectedClientId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [origineDB, setOrigineDB] = useState(null);

  // Ref pour la liste des messages pour permettre le scroll automatique
  const messagesEndRef = useRef(null);

  // Scroller automatiquement vers le bas de la liste des messages à chaque changement
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Récupérer les informations de l'utilisateur connecté
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserInfo({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || 'Nom non disponible',
      });
    }
  }, []);

  // Charger et filtrer les messages pour le client sélectionné
  useEffect(() => {
    if (userInfo && selectedClientId) {
      const chatRef = dbRef(database, `messages`);
      const unsubscribe = onValue(chatRef, (snapshot) => {
        const data = snapshot.val();
        const filteredLists = [];

        const premiersCaracteres = userInfo.email.substring(0, 4);
        const resultat = premiersCaracteres.charAt(0).toUpperCase() + premiersCaracteres.slice(1).toLowerCase();

        // Filtrer les messages
        Object.keys(data || {}).forEach((key) => {
          const origin = key;
          const keyfiltred = key.split(' - ')[0];

          if (key.startsWith(selectedClientId) && keyfiltred.endsWith(resultat)) {
            setOrigineDB(origin);
            filteredLists.push(...Object.values(data[key]));
          }
        });

        setMessages(filteredLists);
      });

      return () => unsubscribe();
    }
  }, [selectedClientId, userInfo]);

  // Fonction d'envoi de message
  const handleSendMessage = () => {
    if (!newMessage.trim() || !userInfo || !origineDB) return;

    const chatRef = dbRef(database, `messages/${origineDB}`);
    const messageData = {
      content: newMessage,
      origin: `${userInfo.email.slice(0, 4)}`,
      timestamp: new Date().toISOString(),
    };

    push(chatRef, messageData).catch((error) => {
      console.error("Erreur lors de l'envoi du message :", error);
    });

    setNewMessage('');
  };

  return (
    <div className="chat-container">
      <h2>Chat avec le client</h2>

      {userInfo && (
        <div className="user-info" style={{ borderStyle: 'groove', borderRadius: '16px' }}>
          <h3>Informations de l'utilisateur connecté :</h3>
          <p><strong>Email :</strong> {userInfo.email}</p>
          <p><strong>UID :</strong> {userInfo.uid}</p>
          <p><strong>Client sélectionné UID :</strong> {selectedClientId}</p>
        </div>
      )}

      {/* Section d'affichage des messages avec défilement et bulles colorées */}
      <div className="messages-list" style={{
          overflowY: 'auto',
          height: '400px',
          display: 'flex',
          flexDirection: 'column', // Inverser pour que les nouveaux messages soient en bas
        }}>
        {messages.length > 0 ? (
          messages.map((message, index) => (
            <div
              key={index}
              className={`message ${message.origin === userInfo.email.slice(0, 4) ? 'user-message' : 'client-message'}`}
              style={{
                backgroundColor: message.origin === userInfo.email.slice(0, 4) ? '#b3e5fc' : '#c8e6c9',
                alignSelf: message.origin === userInfo.email.slice(0, 4) ? 'flex-end' : 'flex-start',
                borderRadius: '15px',
                padding: '10px',
                margin: '5px 0',
                maxWidth: '70%',
              }}
            >
              <p>{message.content}</p>
              <span style={{ fontSize: '0.8em', color: '#666' }}>
                {new Date(message.timestamp).toLocaleString()}
              </span>
            </div>
          ))
        ) : (
          <p>Aucun message pour le moment.</p>
        )}
        {/* Référence pour le défilement automatique */}
        <div ref={messagesEndRef}></div>
      </div>

      {/* Section pour envoyer un message */}
      <div className="send-message-section" style={{ display: 'flex', alignItems: 'center', gap: '8px', marginTop: '10px' }}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Tapez votre message..."
          style={{ flex: 1, padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
        />
        <button onClick={handleSendMessage} style={{ padding: '10px 20px', borderRadius: '5px', cursor: 'pointer' }}>
          Envoyer
        </button>
      </div>
    </div>
  );
};

export default Chat;
